// 查询城市分类模板列表
import consumer_web_policyConfig_cityTemplateList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_cityTemplateList';
// 删除城市分类模板
import consumer_web_policyConfig_deleteCityTemplate from '@/lib/data-service/haolv-default/consumer_web_policyConfig_deleteCityTemplate';





export default {
    name: "hotelStandardList",
    data() {
        return {
            form:{
                // 查询页码
                currentPage: 1,
                orderBy: '',
                pageSize: 9,
                templateName: '',
                totalCount: 0,
                totalPage: 0,
            },
            loading: false,
            templateList: [],
            searchText: '',
            rules: {
                name: [
                    { required: true, message: '请输入活动名称', trigger: 'blur' },
                    { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
            },
            ruleForm: {
                name: '',
            },
        }
    },
    activated(){
        this.initList();
    },
    mounted(){

    },
    methods: {
        // 新增
        addTemplate(){
            this.$router.push({
                name: 'admin-subsidy-template-item-edit',
                query:{
                    templateId: '',
                }
            });
        },
        // 详情
        clickDetails(val){
            this.$router.push({
                name: 'admin-subsidy-template-item',
                query:{
                    templateId: val.templateId,
                }
            });
        },
        // 编辑
        clickEdit(val){
            this.$router.push({
                name: 'admin-subsidy-template-item-edit',
                query:{
                    templateId: val.templateId,
                }
            });
        },
        // 删除
        clickDelete(val){
            this.$confirm('确定删除该模板吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let templateId = val ? val.templateId : '';
                this.loading = true;
                consumer_web_policyConfig_deleteCityTemplate({templateId}).then(res => {
                    this.loading = false;
                    this.$message({
                        message: '删除成功!',
                        type: 'success'
                    });
                    this.initList();
                },error => {
                    this.loading = false;
                    this.$alert(error.data.msg, '提示', {
                        confirmButtonText: '确定',
                        callback: action => {

                        }
                    });
                });
            }).catch(() => {

            });
        },
        handleCurrentChange(currPage){
            this.form.currentPage = currPage;
            this.initList();
        },
        initList(val){
            this.loading = true;
            let templateNameBox = val ? val : '';
            this.form.templateName = templateNameBox;
            consumer_web_policyConfig_cityTemplateList(this.form)
                .then(res => {
                    this.loading = false;
                    if (res.datas && res.datas.templateList){
                        this.templateList = res.datas.templateList.list;
                        this.form.currentPage = res.datas.templateList.currPage;
                        this.form.totalCount = res.datas.templateList.totalCount;
                        this.form.totalPage = res.datas.templateList.totalPage;
                    }
                })
                .catch(rej => {
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        back() {
            this.$router.back();
        }
    }
}